import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { cn } from '../utils';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  ...props
}: CalendarProps & { className?: string }) {
  return (
    <DayPicker
      captionLayout="dropdown"
      className={cn('p-3', className)}
      styles={{
        month: { marginLeft: 0 },
      }}
      classNames={{
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'flex items-center gap-1 text-base font-medium flex',
        nav: 'space-x-1 flex items-center absolute right-3',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        dropdowns: 'flex gap-2',
        button_next: 'disabled:pointer-events-none disabled:opacity-30',
        button_previous: 'disabled:pointer-events-none disabled:opacity-30',
        years_dropdown: 'appearance-none opacity-0 absolute w-full top-0',
        months_dropdown: 'appearance-none opacity-0 absolute w-full top-0',
        dropdown_root: 'relative inline-flex items-center',
        head_cell:
          'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day_button: 'w-full h-full hover:bg-foreground/5 hover:text-foreground',
        day: cn(
          'text-center hover:bg-foreground/5 hover:text-foreground w-full h-full',
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
        ),
        day_range_end: 'day-range-end',
        today: '[&>button]:border',
        outside:
          'day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground',
        disabled: 'text-muted-foreground opacity-50',
        selected:
          'bg-primary text-background hover:bg-primary hover:[&>button]:text-background focus:bg-primary focus:text-background',
        // range_start: cn(
        //   '[&>button]:bg-primary text-background [&>button]:rounded-full hover:[&>button]:bg-primary hover:[&>button]:text-background focus:[&>button]:bg-primary focus:[&>button]:text-background',
        //   'bg-[linear-gradient(-90deg,blue_50%,transparent_50%)]',
        // ),
        // range_end: cn(),
        range_middle:
          'aria-selected:bg-primary/[0.30] [&>button]:rounded-none [&>button]:hover:text-foreground rounded-none aria-selected:text-foreground',
        hidden: 'invisible',
        weekday: 'text-center',
        chevron: 'fill-foreground',
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
