import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { CartLineItem, LineItemError } from 'types/cart';
export type CartLineItemErrorMessages = {
  [lineItemID: CartLineItem['ID']]: LineItemError;
};

/* example state:

{
  1001: {
    rentalDates: "Rentals must be in the future"
  },
  1002: {
    rentalDates: "Rentals must be less than 100 days"
  },
  1005: {
    quantity: "Must be greater than or equal to 0"
  }
}

*/

const defaultState = {};
export default function cartLineItemErrorMessagesReducer(
  state: ReducerInputState<CartLineItemErrorMessages> = defaultState,
  action: Action,
): CartLineItemErrorMessages {
  switch (action.type) {
    case 'CHECKOUT_FAIL':
      if (action.lineItemErrors == null) {
        return state;
      }
      return Object.keys(action.lineItemErrors || {}).reduce(
        (lineItemErrors, lineItemID) => {
          const errorMessage =
            action.lineItemErrors != null && action.lineItemErrors[lineItemID];
          // backend returns string keys, we want numeric keys
          return Object.assign({}, lineItemErrors, {
            [parseInt(lineItemID)]: {
              Generic: errorMessage,
            },
          });
        },
        {},
      );
    // Reset the line item errors when the cart an item is added or removed
    case 'ADD_PRODUCT_TO_CART_SUCCESS':
    case 'REMOVE_CART_LINE_ITEM_SUCCESS':
      return defaultState;
    case 'CHANGE_CART_LINE_ITEM_SUCCESS':
      // clear error messages for the successfully changed line item
      return Object.assign({}, state, {
        [action.changedLineItemID]: null,
      });
    case 'CHANGE_CART_LINE_ITEM_FAIL':
      return Object.assign({}, state, {
        [action.lineItemID]: {
          [action.failureMode]: action.message,
        },
      });
    default:
      return state;
  }
}
