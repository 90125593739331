import * as React from 'react';
import { format, isBefore } from 'date-fns';
import { CalendarIcon } from 'lucide-react';

import { cn } from '../utils';
import { Button } from './button';
import { Calendar, CalendarProps } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { DateRange } from 'react-day-picker';
import { readableDateFormat } from '../utils/time';

export type Range = {
  to: Date | undefined;
  from: Date | undefined;
};

export type DatepickerProps = {
  onSelect: (date: DateRange) => void;
  onClose?: () => void;
  range?: DateRange;
  headerContent?: React.ReactNode;
  buttonProps?: React.ComponentProps<typeof Button>;
  calendarProps?: Omit<CalendarProps, 'selected' | 'mode' | 'required'>;
};

const tabButtonClassName = 'rounded-full text-sm px-2 py-1 h-auto w-20';

export function DateRangePicker({
  onSelect,
  range,
  calendarProps,
  onClose,
  buttonProps,
  headerContent,
}: DatepickerProps) {
  const [selectingStart, setSelectingStart] = React.useState(!range?.from);

  return (
    <Popover
      onOpenChange={(open) => {
        if (!open) {
          onClose?.();
        }
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          {...buttonProps}
          className={cn(
            'justify-start text-left font-normal',
            !range && 'text-muted-foreground',
            buttonProps?.className,
          )}
        >
          <CalendarIcon className="w-4 h-4 mr-2 opacity-50" />
          {range?.to && range?.from ? (
            <div className="flex gap-1">
              <span>{format(range.from, readableDateFormat)}</span>
              <span className="opacity-75">-</span>
              <span>{format(range.to, readableDateFormat)}</span>
            </div>
          ) : (
            <span className="opacity-70">Pick a date range</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <div className="flex flex-col">
          {headerContent}

          <div
            className={cn(
              'border-b border-white/20 flex gap-2 p-2 justify-center',
              headerContent && 'border-t',
            )}
          >
            <Button
              className={cn(
                tabButtonClassName,
                selectingStart && 'bg-white/20 hover:bg-white/20',
              )}
              onClick={() => setSelectingStart(true)}
              variant="ghost"
            >
              Start
            </Button>
            <Button
              className={cn(
                tabButtonClassName,
                !selectingStart && 'bg-white/20 hover:bg-white/20',
              )}
              onClick={() => setSelectingStart(false)}
              variant="ghost"
            >
              End
            </Button>
          </div>
        </div>

        <Calendar
          mode="range"
          required
          {...calendarProps}
          selected={range}
          onSelect={(newRange) => {
            if (
              selectingStart &&
              newRange.to &&
              range?.to &&
              isBefore(newRange.to, range?.to)
            ) {
              onSelect({ from: newRange.to, to: range?.to });
            } else {
              onSelect(newRange);
            }

            setSelectingStart(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
}
