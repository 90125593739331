import React, { ReactNode } from 'react';
import { cn } from '../utils/cn';
import { cva, type VariantProps } from 'class-variance-authority';

const messageVariants = cva('px-4 py-3 rounded-sm', {
  variants: {
    variant: {
      default:
        'bg-violet-900/50 border border-violet-500 text-slate-100 text-base',
      negative: 'bg-red-600/40 border border-red-500 text-base',
      positive: 'bg-green-600/40 border border-green-500 text-base',
      warning: 'bg-orange-600/[0.25] border border-orange-500 text-base',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface MessageProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof messageVariants> {
  asChild?: boolean;
}

export const Message = React.forwardRef<HTMLDivElement, MessageProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <div
        className={cn(messageVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Message.displayName = 'Message';

export const MessageWithIcon = (
  props: MessageProps & {
    icon: ReactNode;
  },
) => {
  const { icon, children } = props;
  return (
    <Message {...props} className={cn('flex gap-2 text-sm', props.className)}>
      <div className="opacity-50">{icon}</div>
      {children}
    </Message>
  );
};

MessageWithIcon.displayName = 'MessageWithIcon';
